<template>
  <div class="mx-1">
    <div class="suitcase-header mt-4" v-if="suitcase_report">
      <div
        class="left-header-text"
        :style="{
          'background-color': facultyColor + '!important',
          color: $lightOrDark(facultyColor) ? 'black' : 'white',
        }"
      >
        <h1
          class="font-weight-bold text-center"
          :style="{ 'background-color': facultyColor }"
        >
          PIA
        </h1>
      </div>
      <h4 class="suitcase-faculty text-left">
        {{ suitcase_report.faculty_name }}
      </h4>
      <div class="logo-container">
        <h4 class="suitcase-title mr-2">
          MALETA <span class="suitcase-title-2">DIDÁCTICA</span>
        </h4>
        <div class="logo-section ml-3">
          <b-img class="institution-logo" :src="institution_logo"></b-img>
        </div>
      </div>
    </div>
    <!-- Encabezado principal con información de asignatura -->
    <div class="synthesis-header-container">
      <div
        class="empty-block"
        :style="{
          'background-color': suitcase_report
            ? facultyColor + '!important'
            : '',
        }"
      ></div>
      <h5 class="synthesis-header mb-0 py-2 text-white">
        <strong>PROGRAMA DE IMPLEMENTACIÓN DE ASIGNATURA</strong>
      </h5>
    </div>
    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{ $getVisibleNames("manual.matter_code", false, "Código") }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >Nombre</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{
              $getVisibleNames("manual.credits", true, "Créditos SCT Totales")
            }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{
              $getVisibleNames(
                "manual.institutional_credits",
                true,
                "Créditos Institucionales"
              )
            }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{
              $getVisibleNames(
                "mesh.modality",
                false,
                "Modalidad de Asignaturas"
              )
            }}</b-th
          >
          <b-th
            class="second-header"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
          >
            {{
              $getVisibleNames(
                "mesh.formationline",
                false,
                "Línea de Formación"
              )
            }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{ suitcase_report.matter_code }}</b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{ suitcase_report.matter_name }}</b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{ suitcase_report.sct_credits }}</b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
            >{{
              suitcase_report.duoc_credits ? suitcase_report.duoc_credits : "-"
            }}</b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
          >
            <template v-if="suitcase_report.modalities.length > 0">
              <div
                v-for="modality in suitcase_report.modalities"
                :key="modality.id"
                :variant="$lightOrDark(facultyColor) ? 'dark' : 'light'"
                class="mr-1"
              >
                {{ modality.name }}
              </div>
            </template>
            <template v-else>
              <div>N/A</div>
            </template></b-td
          >
          <b-td
            class="align-middle"
            :style="{
              'background-color': facultyColor + '!important',
              color: $lightOrDark(facultyColor) ? 'black' : 'white',
            }"
          >
            {{ suitcase_report.formation_line }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!-- *********************** SECCIÓN DE ANTECEDENTES GENERALES ***********************-->

    <div class="first-header item-one">
      <span class="general-background-icon mr-2">
        <b-icon icon="list-check"></b-icon>
      </span>
      1. ANTECEDENTES GENERALES
    </div>

    <div v-if="suitcase_report" class="table-container mb-3">
      <!-- Fila 1 -->
      <!-- Nombre Carrera/s -->
      <div v-if="suitcase_report.matter_is_transversal == false" class="d-flex">
        <div class="primary-cell">Nombre Carrera/s</div>
        <div class="secundary-cell d-block">
          <div
            v-for="(career, index) in distinctCareer(
              suitcase_report.competence_dict_list
            )"
            :key="index"
          >
            {{ career }}
          </div>
        </div>
      </div>
      <!-- Plan de Estudio -->
      <div v-if="suitcase_report.matter_is_transversal == false" class="d-flex">
        <div class="primary-cell">Planes de Estudio</div>
        <div class="secundary-cell">
          <div
            class="d-block"
            v-for="(
              egress_profile, index
            ) in suitcase_report.competence_dict_list"
            :key="index"
          >
            {{ egress_profile.egress_profile_name }}
          </div>
        </div>
      </div>
      <!-- Fila 2 -->
      <!-- Horas Totales -->
      <div class="d-flex">
        <div class="primary-cell">Horas Totales</div>
        <div class="secundary-cell">
          <span>{{
            suitcase_report.total_hours ? suitcase_report.total_hours : "0"
          }}</span>
        </div>
      </div>
      <!-- N° de Semanas de Programación -->
      <div class="d-flex">
        <div class="primary-cell">N° de Semanas de Programación</div>
        <div class="secundary-cell">
          <span>{{ suitcase_report.weeks ? suitcase_report.weeks : "0" }}</span>
        </div>
      </div>
    </div>

    <div class="table-container2">
      <!-- Fila 1 -->
      <!-- Docencia Dirigida Presencial / No Presencial -->
      <div class="title-primary-cell">Docencia Dirigida Presencial</div>
      <div class="title-primary-cell">Docencia Dirigida No Presencial</div>
    </div>

    <div v-if="suitcase_report" class="table-container mb-3">
      <!-- Fila 2 -->
      <!-- Horas Pedagogicas - Presencial -->
      <div class="d-flex">
        <div class="primary-cell">Horas Pedagógicas</div>
        <div class="secundary-cell">
          <span>
            <!-- {{
              suitcase_report.in_person_hours
                ? suitcase_report.in_person_hours
                : "0"
            }} -->
            {{
              suitcase_report.matter_time_allocations
                .map(
                  (x) =>
                    `${x.matter_time_allocation_name}: ${x.matter_time_allocation_hours}`
                )
                .join(", ")
            }}
          </span>
        </div>
      </div>
      <!-- Horas Pedagogicas - NO Presencial -->
      <div class="d-flex">
        <div class="primary-cell">Horas Pedagógicas</div>
        <div class="secundary-cell">
          <span>
            {{
              suitcase_report.online_hours ? suitcase_report.online_hours : "0"
            }}
          </span>
        </div>
      </div>

      <!-- Fila 3 -->
      <!-- Ambiente - Presencial -->
      <div class="d-flex">
        <div class="primary-cell">Ambiente</div>
        <div class="secundary-cell">
          <template
            v-if="
              suitcase_report.in_person_environments &&
              suitcase_report.in_person_environments.length > 0
            "
          >
            <div>
              {{
                suitcase_report.in_person_environments
                  .map((x) => x.name)
                  .join(", ")
              }}
            </div>
          </template>
        </div>
      </div>
      <!-- Ambiente - NO Presencial -->
      <div class="d-flex">
        <div class="primary-cell">Ambiente</div>
        <div class="secundary-cell">
          <template
            v-if="
              suitcase_report.online_environments &&
              suitcase_report.online_environments.length > 0
            "
          >
            <div
              v-for="(
                environment, index
              ) in suitcase_report.online_environments"
              :key="environment.id"
              class="mr-1"
            >
              {{ index > 0 ? ", " : "" }}{{ environment.name
              }}{{ environment.name }}
            </div>
          </template>
        </div>
      </div>

      <!-- Fila 4 -->
      <!-- N° de Estudiantes Sugeridos - Presencial -->
      <div class="d-flex">
        <div class="primary-cell">N° de Estudiantes Sugerido</div>
        <div class="secundary-cell">
          <span>{{
            suitcase_report.in_person_student_amount
              ? suitcase_report.in_person_student_amount
              : "0"
          }}</span>
        </div>
      </div>
      <!-- N° de Estudiantes Sugeridos - NO Presencial -->
      <div class="d-flex">
        <div class="primary-cell">N° de Estudiantes Sugerido</div>
        <div class="secundary-cell">
          <span>{{
            suitcase_report.online_student_amount
              ? suitcase_report.online_student_amount
              : "0"
          }}</span>
        </div>
      </div>

      <!-- Fila 5 -->
      <!-- Horas Cronologicas de Trabajo Autonomo - Presencial -->
      <div class="d-flex">
        <div class="primary-cell">Horas Cronológicas de Trabajo Autónomo</div>
        <div class="secundary-cell">
          <span>{{
            suitcase_report.autonomous_work_hours
              ? suitcase_report.autonomous_work_hours
              : "0"
          }}</span>
        </div>
      </div>
      <!-- N° Horas Ayudantía - NO Presencial -->
      <div class="d-flex">
        <div class="primary-cell">N° Horas Ayudantía</div>
        <div class="secundary-cell">
          <span>{{
            suitcase_report.assistantship_hours
              ? suitcase_report.assistantship_hours
              : "0"
          }}</span>
        </div>
      </div>

      <!-- Fila 6 -->
      <!-- Asignatura(s) Refenciadas - Presencial -->
      <div class="d-flex">
        <div class="primary-cell">
          {{ suitcase_report.referenced_matters_title }}
        </div>
        <div class="d-block secundary-cell">
          <div
            class="rich-text-content"
            v-html="suitcase_report.referenced_matters"
          ></div>
        </div>
      </div>
      <!-- Pre-requisitos - NO Presencial -->
      <div class="d-flex">
        <div class="primary-cell">Prerrequisitos</div>
        <div class="secundary-cell">
          <span>
            {{
              suitcase_report.prerequisites
                .map((prerequisite) => prerequisite.matter__name)
                .join(", ")
            }}
          </span>
        </div>
      </div>
    </div>

    <!-- <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th colspan="9" class="first-header"
            ><span class="general-background-icon mr-2">
              <b-icon icon="list-check"></b-icon>
            </span>
            1. ANTECEDENTES GENERALES
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="second-header">Horas totales</b-th>
          <b-td>
            <template v-if="suitcase_report.total_hours">
              <div>
                {{ suitcase_report.total_hours }}
              </div>
            </template>
            <template v-else>
              <div>-</div>
            </template></b-td
          >
          <b-th class="second-header">Presenciales</b-th>
          <b-th class="second-header">Horas</b-th>
          <b-th class="second-header">N° Estudiantes</b-th>
          <b-th class="second-header">No Presenciales</b-th>
          <b-th class="second-header">Horas</b-th>
          <b-th class="second-header">N° Estudiantes</b-th>
          <b-th class="second-header"
            >Total horas de trabajo Autónomo (Cronológicas)</b-th
          >
        </b-tr>
        <b-tr>
          <b-th colspan="2" class="second-header">Ambiente/Horas</b-th>
          <b-td>
            <template
              v-if="
                suitcase_report.in_person_environments &&
                suitcase_report.in_person_environments.length > 0
              "
            >
              <b-badge
                v-for="environment in suitcase_report.in_person_environments"
                :key="environment.id"
                class="mr-1"
              >
                {{ environment.name }}
              </b-badge>
            </template>
            <template v-else>
              <div>N/A</div>
            </template>
          </b-td>
          <b-td>{{
            suitcase_report.in_person_hours
              ? suitcase_report.in_person_hours
              : "-"
          }}</b-td>
          <b-td>{{
            suitcase_report.in_person_student_amount
              ? suitcase_report.in_person_student_amount
              : "-"
          }}</b-td>
          <b-td>
            <template
              v-if="
                suitcase_report.online_environments &&
                suitcase_report.online_environments.length > 0
              "
            >
              <b-badge
                v-for="environment in suitcase_report.online_environments"
                :key="environment.id"
                class="mr-1"
              >
                {{ environment.name }}
              </b-badge>
            </template>
            <template v-else>
              <div>N/A</div>
            </template>
          </b-td>
          <b-td>
            {{
              suitcase_report.online_hours ? suitcase_report.online_hours : "-"
            }}
          </b-td>
          <b-td>
            {{
              suitcase_report.online_student_amount
                ? suitcase_report.online_student_amount
                : "-"
            }}
          </b-td>
          <b-td> {{ suitcase_report.autonomous_work_hours }} </b-td>
        </b-tr>
        <b-tr>
          <b-th colspan="3" class="second-header">N° Horas Ayudantía</b-th>
          <b-td colspan="2">{{ suitcase_report.assistantship_hours }}</b-td>
          <b-th colspan="2" class="second-header">Prerrequisito</b-th>
          <b-td colspan="2"
            ><b-badge
              class="pre-requisites-tag"
              v-for="prerequisite in suitcase_report.prerequisites"
              :key="prerequisite.id"
              :style="{
                'background-color': prerequisite
                  ? prerequisite.color
                  : default_bg_color,
                color:
                  (prerequisite && $lightOrDark(prerequisite.color)) ||
                  !prerequisite
                    ? default_color
                    : default_bg_color,
              }"
            >
              {{ prerequisite.matter__name }}
            </b-badge></b-td
          >
        </b-tr>
      </b-thead>
    </b-table-simple> -->

    <!-- *********************** SECCIÓN OTRAS INDICACIONES ***********************-->
    <template v-if="suitcase_report">
      <div class="suitcase-program-indications">
        <div class="second-header pt-3 pb-3 pl-3 text-left">
          <strong>
            {{ suitcase_report.outside_activities_indications_title }}
          </strong>
        </div>
        <template v-if="suitcase_report.outside_activities_indications">
          <div class="descriptor-body pt-2 pb-3 rich-text-content">
            <!-- outside_activities_indications -->
            <div
              v-html="suitcase_report.outside_activities_indications"
              class="rich-text-content"
            ></div>
          </div>
        </template>
        <template v-else>
          <div class="program-indications-body">No hay Orientaciones.</div>
        </template>
      </div>
    </template>

    <template v-if="suitcase_report">
      <div class="suitcase-program-indications">
        <div class="second-header pt-3 pb-3 pl-3 text-left">
          <strong> Otras indicaciones para la programación </strong>
        </div>
        <template v-if="suitcase_report.program_indications">
          <div
            class="descriptor-body pt-2 pb-3 rich-text-content"
            v-html="suitcase_report.program_indications"
          ></div>
        </template>
        <template v-else>
          <div class="program-indications-body">No hay Orientaciones.</div>
        </template>
      </div>
      <template>
        <div class="suitcase-matter-description mt-2">
          <div class="matter-description-header text-left">
            <span class="general-background-icon mr-2">
              <b-icon icon="gear"></b-icon>
            </span>
            2. DESCRIPCIÓN DE LA ASIGNATURA
          </div>
          <div class="matter-description-body">
            <template v-if="suitcase_report.matter_description">
              <div
                class="rich-text-content"
                v-html="suitcase_report.matter_description"
              ></div>
            </template>
            <template v-else>
              <div>No hay descripción para la Asignatura.</div>
            </template>
          </div>
        </div>
      </template>
    </template>

    <!-- *********************** SECCIÓN TABLA DE COMPETENCIAS ***********************-->

    <b-table-simple
      v-if="suitcase_report && suitcase_report.competence_dict_list.length > 0"
      class="mt-2 mb-2 page-break"
      bordered
      responsive
    >
      <template v-if="suitcase_report.competence_dict_list.length > 0">
        <!-- Capacidades Institucionales -->
        <!-- TODO: OCULTADO TABLA CAPACIDADES -->
        <b-tr>
          <b-th class="suitcase-competences-th text-justify" style="width: 18%">
            {{ $getVisibleNames("mesh.capacity", true, "Capacidades") }}
          </b-th>
          <b-td class="suitcase-competences-td text-justify">
            <div v-if="suitcase_report.capacities.length == 0">
              No hay
              {{ $getVisibleNames("mesh.capacity", true, "Capacidades") }} para
              la Asignatura.
            </div>
            <div v-else>
              <div>
                {{ suitcase_report.capacities.join(", ") }}
              </div>
            </div>
          </b-td>
        </b-tr>
        <template
          v-for="competence_dict_list in suitcase_report.competence_dict_list"
        >
          <template
            v-for="(competence, index) in competence_dict_list.competences"
          >
            <b-tr
              :key="
                competence_dict_list.egress_profile_name + '-' + competence.id
              "
            >
              <b-th
                :rowspan="competence_dict_list.competences.length"
                v-if="index == 0"
                class="suitcase-competences-th text-justify"
                style="width: 300px"
              >
                {{ $getVisibleNames("mesh.competence", true, "Competencias") }}:
                <template
                  v-if="suitcase_report.competence_dict_list.length > 1"
                >
                  "{{ competence_dict_list.egress_profile_name }}"
                </template>
              </b-th>
              <b-td class="suitcase-competences-td text-justify">
                <!-- Falta Orden de la competencia del perfil... -->
                <span v-if="competence.order">{{
                  "C" + competence.order + "."
                }}</span>
                {{ competence.full_sentence }}</b-td
              >
            </b-tr>
          </template>
        </template>
      </template>
    </b-table-simple>

    <!-- *********************** SECCIÓN TABLA DE RESULTADOS DE APRENDIZAJE ***********************-->

    <b-table-simple
      v-if="suitcase_report && suitcase_report.learning_results.length"
      bordered
      responsive
      class="mt-1 page-break"
    >
      <template
        v-for="(learning_result, index) in suitcase_report.learning_results"
      >
        <b-tr :key="learning_result.id">
          <b-th
            :rowspan="suitcase_report.learning_results.length"
            v-if="index == 0"
            class="suitcase-learning-results-th"
            style="width: 18%"
          >
            Resultados de Aprendizaje</b-th
          >
          <b-td class="suitcase-learning-results-td">
            <!-- Falta Orden del resultado de aprendizaje del perfil... -->
            <span v-if="learning_result.order">{{
              "RA" + learning_result.order + "."
            }}</span>
            {{ learning_result.full_sentence }}</b-td
          >
        </b-tr>
      </template>
    </b-table-simple>

    <!-- *********************** SÍNTESIS RUTA DE APRENDIZAJE ***********************-->

    <b-table-simple v-if="suitcase_report" bordered small class="page-break">
      <b-thead>
        <b-tr>
          <b-th class="learning-results-synthesis-th text-left" colspan="7">
            <span class="general-background-icon mr-2">
              <b-icon icon="clipboard-data"></b-icon>
            </span>
            3. SÍNTESIS DE LA RUTA DE APRENDIZAJE
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="fifth-header align-middle"
            >RESULTADOS DE APRENDIZAJE</b-th
          >
          <b-th class="fifth-header align-middle"
            >EXPERIENCIA DE APRENDIZAJE</b-th
          >
          <b-th class="fifth-header align-middle">HORAS</b-th>
          <b-th class="fifth-header align-middle">AMBIENTE DE APRENDIZAJE</b-th>
          <b-th class="fifth-header align-middle">TIPO DE EVALUACIÓN</b-th>
          <b-th class="fifth-header align-middle">PONDERACIÓN</b-th>
          <b-th class="fifth-header align-middle">PONDERACIÓN FINAL</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template
          v-if="
            suitcase_report.learning_experiences.length == 0 &&
            suitcase_report.transversal_exams.length == 0
          "
        >
          <b-tr>
            <b-td :colspan="6">No hay datos para mostrar.</b-td>
          </b-tr>
        </template>
        <template
          v-for="(
            learning_result, index
          ) in suitcase_report.learning_experiences"
        >
          <template v-for="(evaluation, index2) in learning_result.evaluations">
            <b-tr
              :key="
                learning_result.id +
                '-' +
                index +
                '-' +
                evaluation.id +
                '-' +
                index2
              "
            >
              <!-- RA -->
              <b-td
                v-if="index2 == 0"
                :rowspan="learning_result.evaluations.length"
                class="align-middle"
              >
                <template v-if="learning_result.study_units.length > 0">
                  <b-badge
                    v-for="study_unit in learning_result.study_units"
                    :key="study_unit.id"
                    pill
                    variant="none"
                    class="ra-badge mr-1 noprint"
                    v-b-tooltip.v-secondary.noninteractive.bottom="
                      study_unit.full_sentence
                    "
                  >
                    RA{{ study_unit.order }}
                  </b-badge>
                  <p
                    class="learning-result"
                    :key="'p-st1' + study_unit.id"
                    v-for="study_unit in learning_result.study_units"
                  >
                    {{ study_unit.full_sentence }}
                  </p>
                </template>
                <template v-else>
                  <div>N/A</div>
                </template>
              </b-td>
              <!-- Experiencias -->
              <b-td
                v-if="index2 == 0"
                :rowspan="learning_result.evaluations.length"
                class="text-center align-middle"
                ><span v-if="learning_result.order">{{
                  "EA " + learning_result.order + " - "
                }}</span
                >{{ learning_result.name }}
              </b-td>
              <!-- Horas -->
              <b-td
                v-if="index2 == 0"
                :rowspan="learning_result.evaluations.length"
                class="align-middle"
              >
                {{ learning_result.le_epm_evaluations_hours }}
              </b-td>
              <!-- Ambientes -->
              <b-td
                v-if="index2 == 0"
                :rowspan="learning_result.evaluations.length"
                class="align-middle"
              >
                <template
                  v-if="learning_result.le_study_environments_list.length > 0"
                >
                  <span>
                    {{ learning_result.le_study_environments_list.join(", ") }}
                  </span>
                </template>
                <template v-else>
                  <div>N/A</div>
                </template>
              </b-td>
              <!-- Evaluaciones -->
              <b-td>
                <template>
                  {{ evaluation.name }}
                </template>
                <!-- <template
                  v-if="learning_result.le_evaluations_name_list.length > 0"
                >
                  <div
                    v-for="(
                      evaluation, index
                    ) in learning_result.le_evaluations_name_list"
                    :key="'le_evaluations_name_list:' + index"
                  >
                    {{ evaluation }}
                  </div>
                </template>
                <template v-else>
                  <div>N/A</div>
                </template> -->
              </b-td>
              <!-- Ponderación -->
              <b-td>
                <template> {{ evaluation.weighing }}%</template>
              </b-td>
              <b-td
                class="text-center align-middle"
                v-if="index == 0 && index2 == 0"
                :rowspan="countRowspanEvaluation"
              >
                {{ getTotalWeighing }}%
              </b-td>
            </b-tr>
          </template>
          <template v-if="learning_result.evaluations.length == 0">
            <b-tr :key="learning_result.id + '-' + index">
              <!-- RA -->
              <b-td class="align-middle">
                <template v-if="learning_result.study_units.length > 0">
                  <b-badge
                    v-for="study_unit in learning_result.study_units"
                    :key="study_unit.id"
                    pill
                    variant="none"
                    class="ra-badge mr-1 noprint"
                    v-b-tooltip.v-secondary.noninteractive.bottom="
                      study_unit.full_sentence
                    "
                  >
                    RA{{ study_unit.order }}
                  </b-badge>
                  <p
                    class="learning-result"
                    :key="'p-st1' + study_unit.id"
                    v-for="study_unit in learning_result.study_units"
                  >
                    {{ study_unit.full_sentence }}
                  </p>
                </template>
                <template v-else>
                  <div>N/A</div>
                </template>
              </b-td>
              <!-- Experiencias -->
              <b-td class="text-center align-middle"
                ><span v-if="learning_result.order">{{
                  "EA " + learning_result.order + " - "
                }}</span
                >{{ learning_result.name }}
              </b-td>
              <!-- Horas -->
              <b-td class="align-middle">
                {{ learning_result.le_epm_evaluations_hours }}
              </b-td>
              <!-- Ambientes -->
              <b-td class="align-middle">
                <template
                  v-if="learning_result.le_study_environments_list.length > 0"
                >
                  <span>
                    {{ learning_result.le_study_environments_list.join(", ") }}
                  </span>
                </template>
                <template v-else>
                  <div>N/A</div>
                </template>
              </b-td>
              <b-td> -<!-- evaluation.name --> </b-td>
              <b-td> -<!-- evaluation.weighing --> </b-td>
              <b-td
                class="text-center align-middle"
                v-if="index == 0"
                :rowspan="countRowspanEvaluation"
              >
                {{ getTotalWeighing }}%
              </b-td>
            </b-tr>
          </template>
        </template>
        <template
          v-if="
            suitcase_report.transversal_exams &&
            suitcase_report.transversal_exams.length > 0
          "
        >
          <template
            v-for="transversal_exam in suitcase_report.transversal_exams"
          >
            <b-tr :key="transversal_exam.id">
              <b-td>
                <template v-if="transversal_exam.study_units.length > 0">
                  <b-badge
                    v-for="(
                      order_transversal, index
                    ) in transversal_exam.study_units"
                    :key="index + 'RA-transversal' + transversal_exam.id"
                    pill
                    variant="none"
                    class="ra-badge mr-1 noprint"
                    v-b-tooltip.v-secondary.noninteractive.bottom="
                      order_transversal.full_sentence
                    "
                  >
                    RA{{ order_transversal.order }}
                  </b-badge>
                  <p
                    class="learning-result"
                    :key="'p-te' + index + transversal_exam.id"
                    v-for="(
                      order_transversal, index
                    ) in transversal_exam.study_units"
                  >
                    {{ order_transversal.full_sentence }}
                  </p>
                </template>
                <template v-else>
                  <div>N/A</div>
                </template>
              </b-td>
              <b-td>
                EXAMEN {{ transversal_exam.type_name.toUpperCase() }}
              </b-td>
              <b-td>{{ transversal_exam.suggested_hours }}</b-td>
              <b-td
                ><template
                  v-if="transversal_exam.study_environments.length > 0"
                >
                  <div
                    v-for="(
                      environment, index
                    ) in transversal_exam.study_environments"
                    :key="environment.id + index"
                    variant="info"
                  >
                    {{ index > 0 ? ", " : "" }}{{ environment.name }}
                  </div>
                </template></b-td
              >
              <b-td>{{ transversal_exam.name }}</b-td>
              <!-- Ponderación -->
              <b-td>-</b-td>
              <b-td>{{ transversal_exam.weighing }}%</b-td>
            </b-tr>
          </template>
          <template>
            <b-tr>
              <b-td colspan="6"> TOTAL</b-td>
              <b-td>{{ getAllTotalWeighing }}%</b-td>
            </b-tr>
          </template>
        </template>
        <template>
          <b-tr>
            <!-- <b-td class="text-left rich-text-content" colspan="6">
            <div
              class="rich-text-content"
              v-html="suitcase_report.learning_route_note"
            ></div>
          </b-td> -->
            <td
              colspan="7"
              v-if="suitcase_report.matter_is_transversal == false"
            >
              <div>
                "Nota: El/la docente puede agregar evaluaciones parciales o
                controles adicionales a las señaladas en este documento. Sin
                embargo, debe asegurarse que las evaluaciones mantengan la
                ponderación asociada a cada experiencia de aprendizaje."
              </div>
            </td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <!-- *********************** EVALUACION FINAL TRANSVERSAL PERFILES DE DOCENTES ***********************-->
    <b-table-simple
      class="page-break"
      v-if="suitcase_report"
      bordered
      responsive
    >
      <b-thead>
        <b-tr>
          <b-th colspan="9" class="first-header text-left"
            ><span class="general-background-icon mr-2">
              <b-icon icon="list-check"></b-icon>
            </span>
            4. EVALUACIÓN FINAL TRANSVERSAL
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <!-- Fila 1 Caracteristicas de la Aplicacion -->
        <b-tr>
          <b-th style="width: 30%" class="transversal-app text-justify">
            Características de la Aplicación
          </b-th>
          <b-td class="transversal-app text-justify"></b-td>
        </b-tr>

        <!-- Fila 2 Tiempo del ET-->
        <b-tr>
          <b-th style="width: 30%" class="text-justify">
            <!-- {{ suitcase_report.et_development_time_title }} -->
            Tiempo para el desarrollo o aplicación del ET
          </b-th>
          <b-td class="suitcase-competences-td text-justify">
            <template v-if="suitcase_report">
              <div
                v-for="et_time in suitcase_report.et_development_time"
                :key="'et_time' + et_time"
              >
                <span>
                  {{ et_time }}
                </span>
              </div>
            </template>
          </b-td>
        </b-tr>
        <!-- Fila 3 Tipo de Trabajo-->
        <b-tr>
          <b-th style="width: 30%" class="text-justify">
            Tipo de trabajo (individual o grupal)</b-th
          >
          <b-td class="suitcase-competences-td text-justify">
            <span>
              {{ [...new Set(suitcase_report.et_work_type)].join(", ") }}
            </span>
          </b-td>
        </b-tr>
        <!-- Fila 4 Evaluador-->
        <b-tr>
          <b-th style="width: 30%" class="text-justify"> Evaluador/a </b-th>
          <b-td class="suitcase-competences-td text-justify">
            <span>
              {{ [...new Set(suitcase_report.et_evaluator)].join(", ") }}
            </span>
          </b-td>
        </b-tr>
        <!-- Fila 5 Lugar de aplicación del ET-->
        <b-tr>
          <b-th style="width: 30%" class="text-justify">
            Lugar de aplicación del ET
          </b-th>
          <b-td class="suitcase-competences-td text-justify"
            ><div
              class="text-content rich-text-content"
              v-html="suitcase_report.et_application_place"
            ></div
          ></b-td>
        </b-tr>
        <!-- Fila 6 Aplicación simultanea o diferida-->
        <b-tr>
          <b-th style="width: 30%" class="text-justify">
            Aplicación simultánea o diferida
          </b-th>
          <b-td class="suitcase-competences-td text-justify"
            ><div
              class="text-content rich-text-content"
              v-html="suitcase_report.et_application_type"
            ></div
          ></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <!-- *********************** SECCIÓN PERFILES DE DOCENTES ***********************-->
    <b-table-simple
      v-if="suitcase_report && suitcase_report.teaching_profiles.length"
      bordered
      responsive
      class="page-break"
    >
      <b-thead>
        <b-th class="first-header text-left" colspan="3">
          <span class="general-background-icon mr-2">
            <b-icon icon="person"></b-icon>
          </span>
          5. DOCENTE(S)
        </b-th>
        <b-th class="third-header">Número de Docentes </b-th>
        <b-th class="fourth-header">
          <!-- v-b-tooltip.v-secondary.noninteractive.bottom="
            'Prerrequisitos de la Asignatura'
          " -->
          <template>
            {{ suitcase_report.teaching_profiles.length }}
          </template>
          <!-- <template v-if="suitcase_report.prerequisites">
            <b-badge
              class="pre-requisites-tag"
              v-for="prerequisite in suitcase_report.prerequisites"
              :key="prerequisite.id"
              variant="light"
            >
              {{ prerequisite.matter__name }}
            </b-badge>
          </template>
          <template v-else>
            <div>Sin Prerrequisitos</div>
          </template> -->
        </b-th>
      </b-thead>
      <b-tbody>
        <template
          v-for="(teaching_profile, index) in suitcase_report.teaching_profiles"
        >
          <b-tr :key="teaching_profile.id + 'first'">
            <b-th class="teaching-profile-header" rowspan="4">
              Perfil Docente {{ index + 1 }}
            </b-th>
            <b-th class="teaching-profile-header text-justify"
              >Horas totales</b-th
            >
            <b-td>{{
              teaching_profile.total_hours ? teaching_profile.total_hours : "-"
            }}</b-td>
            <b-th class="teaching-profile-header text-justify">
              Especialización
            </b-th>
            <b-td>
              <div
                v-html="teaching_profile.specialization_competences"
                class="rich-text-content"
              ></div>
            </b-td>
          </b-tr>
          <b-tr :key="teaching_profile.id + 'second'">
            <b-th class="teaching-profile-header text-justify"
              >Años de experiencia laboral en el área</b-th
            >
            <b-td>{{
              teaching_profile.experience_years
                ? teaching_profile.experience_years
                : "-"
            }}</b-td>
            <b-th class="teaching-profile-header text-justify">
              Habilitaciones docente requeridas
            </b-th>
            <b-td>
              <template v-if="teaching_profile.teacher_habilitations">
                <div
                  class="rich-text-content"
                  v-html="teaching_profile.teacher_habilitations"
                ></div>
              </template>
              <template v-else>
                <div>N/A</div>
              </template>
            </b-td>
          </b-tr>
          <b-tr :key="teaching_profile.id + 'third'">
            <!-- Columna "Formación" con rowspan -->
            <b-th class="teaching-profile-header text-justify" rowspan="2">
              Formación
            </b-th>
            <b-td rowspan="2">
              <template v-if="teaching_profile.work_experience">
                <div
                  class="rich-text-content"
                  v-html="teaching_profile.work_experience"
                ></div>
              </template>
              <template v-else>
                <div>N/A</div>
              </template>
            </b-td>
            <b-th class="teaching-profile-header text-justify"
              >Vigencia Experiencia Laboral</b-th
            >
            <b-td>
              <template v-if="teaching_profile.work_experience_validity">
                <div
                  v-html="teaching_profile.work_experience_validity"
                  class="rich-text-content"
                ></div>
              </template>
              <template v-else>
                <div>N/A</div>
              </template>
            </b-td>
          </b-tr>
          <b-tr :key="teaching_profile.id + 'fourth'">
            <b-th class="teaching-profile-header text-justify"
              >Observaciones</b-th
            >
            <b-td>
              <template v-if="teaching_profile.observations">
                <div
                  v-html="teaching_profile.observations"
                  class="rich-text-content"
                ></div>
              </template>
              <template v-else>
                <div>N/A</div>
              </template>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>

    <!-- *********************** SECCIÓN AYUDANTES ***********************-->

    <template v-if="suitcase_report">
      <div class="assistant-header text-left">
        <span class="general-background-icon mr-2">
          <b-icon icon="person"></b-icon>
        </span>
        6. AYUDANTE
      </div>
      <div
        class="assistant-container"
        v-for="(assistant, index) in suitcase_report.assistant_profiles"
        :key="assistant.id"
      >
        <div class="w-50">
          <div class="assistant-drop-header">
            Perfil Ayudante {{ index + 1 }}
          </div>
          <template v-if="assistant.profile">
            <div
              class="assistant-content rich-text-content"
              v-html="assistant.profile"
            ></div>
          </template>
          <template v-else>
            <div class="assistant-content">N/A</div>
          </template>
        </div>
        <div class="w-50">
          <div class="assistant-drop-header">Rol del Ayudante</div>
          <template v-if="assistant.role">
            <div
              class="assistant-content rich-text-content"
              v-html="assistant.role"
            ></div>
          </template>
          <template v-else>
            <div class="assistant-content">N/A</div>
          </template>
        </div>
      </div>
    </template>

    <!-- *********************** SECCIÓN RECURSOS BIBLIOGRÁFICOS ***********************-->

    <b-table-simple
      v-if="suitcase_report"
      bordered
      responsive
      class="mt-2 page-break"
    >
      <b-thead>
        <b-th class="first-header text-left" colspan="4">
          <span class="general-background-icon mr-2">
            <b-icon icon="gear-wide-connected"></b-icon>
          </span>
          7. RECURSOS PARA LA IMPLEMENTACIÓN
        </b-th>
      </b-thead>
      <b-tbody>
        <template
          v-if="
            suitcase_report.bibliographic_resources.filter((x) =>
              $equals(x.type__name, 'Obligatoria')
            ).length > 0
          "
        >
          <template
            v-for="(
              biblio, index
            ) in suitcase_report.bibliographic_resources.filter((x) =>
              $equals(x.type__name, 'Obligatoria')
            )"
          >
            <b-tr :key="biblio.id">
              <b-th
                v-if="index == 0"
                class="biblio-header"
                :rowspan="suitcase_report.bibliographic_resources.length"
                >Bibliografía Obligatoria</b-th
              >
              <b-td>
                <template v-if="biblio.description">
                  <div
                    class="rich-text-content text-justify bibliography-content"
                    v-html="biblio.description"
                  ></div>
                </template>
                <template v-else>
                  <div>N/A</div>
                </template>
                <!-- {{ biblio.type__name }}, {{ biblio.description }} -->
              </b-td>
              <b-th
                v-if="index == 0"
                class="biblio-header"
                :rowspan="suitcase_report.bibliographic_resources.length"
                >Recursos por Ambiente de Aprendizaje (referencia Ficha
                FER)</b-th
              >
              <b-td
                v-if="index == 0"
                :rowspan="suitcase_report.bibliographic_resources.length"
                class="text-center align-middle"
              >
                <div v-if="oldMatter">
                  <div
                    class="rich-text-content"
                    v-html="oldMatter.fer_record"
                  ></div>
                  <!-- <div v-if="allows_crud">
                  <button-edit
                    tabindex="-1"
                    class="w-30"
                    @click="
                      $bvModal.show(`edit-fer_record-modal-${oldMatter.id}`)
                    "
                    v-b-tooltip.v-secondary.noninteractive="
                      'Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)'
                    "
                  >
                  </button-edit>
                  <b-modal
                    :id="`edit-fer_record-modal-${oldMatter.id}`"
                    title="Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)"
                    size="lg"
                    hide-footer
                  >
                    <NewRichTextEditor
                      :Object="oldMatter"
                      :Text="oldMatter.fer_record"
                      @save="updateFerRecord"
                      @close="
                        $bvModal.hide(`edit-fer_record-modal-${oldMatter.id}`)
                      "
                    ></NewRichTextEditor>
                  </b-modal>
                </div> -->
                </div>
              </b-td>
            </b-tr>
          </template>
        </template>
        <template v-else>
          <b-tr>
            <b-th class="biblio-header">Bibliografía Obligatoria</b-th>
            <b-td>
              <div></div>
            </b-td>
            <b-th class="biblio-header"
              >Recursos por Ambiente de Aprendizaje (referencia Ficha FER)</b-th
            >
            <b-td class="text-center align-middle">
              <div v-if="oldMatter">
                <div
                  class="rich-text-content"
                  v-html="oldMatter.fer_record"
                ></div>
                <!-- <div v-if="allows_crud">
                  <button-edit
                    tabindex="-1"
                    class="w-30"
                    @click="
                      $bvModal.show(`edit-fer_record-modal-${oldMatter.id}`)
                    "
                    v-b-tooltip.v-secondary.noninteractive="
                      'Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)'
                    "
                  >
                  </button-edit>
                  <b-modal
                    :id="`edit-fer_record-modal-${oldMatter.id}`"
                    title="Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)"
                    size="lg"
                    hide-footer
                  >
                    <NewRichTextEditor
                      :Object="oldMatter"
                      :Text="oldMatter.fer_record"
                      @save="updateFerRecord"
                      @close="
                        $bvModal.hide(`edit-fer_record-modal-${oldMatter.id}`)
                      "
                    ></NewRichTextEditor>
                  </b-modal>
                </div> -->
              </div>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <EvaluationCustomFab
      class="noprint"
      :matter_id="egress_profile_matter_id"
      :show_matter_program="true"
    ></EvaluationCustomFab>
  </div>
</template>
<script>
// import { toast } from "@/utils/utils";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "LearningSuitcaseView",
  components: {
    EvaluationCustomFab: () =>
      import("@/components/evaluations/EvaluationCustomFab"),
    // NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  data() {
    return {
      egress_profile_matter_id: Number(
        this.$route.params.egress_profile_matter_id
      ),
      // egress_profile_linkage: null,
      suitcase_report: null,
      default_color: "var(--primary-font-color)",
      default_bg_color: "#f8f9fa",
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      institution_logo: "getInstitutionLogo",
      user: "getUser",
      user_position: names.USER_POSITION,
      images: names.IMAGES,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
    }),
    egressProfileMatter() {
      if (!this.egress_profile_matter_id) return null;
      return this.egressProfileMatters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    facultyColor() {
      if (this.suitcase_report) {
        if (this.suitcase_report.matter_is_transversal) return "black";
        else return this.suitcase_report.faculty_color;
      }
      return "black";
    },
    oldMatter() {
      if (!this.egressProfileMatter) return null;
      return this.matters.find((x) => x.id == this.egressProfileMatter.matter);
    },
    getTotalWeighing() {
      let total_weighing = 0;
      let total_weighing_sixty = 0;
      if (this.suitcase_report) {
        this.suitcase_report.learning_experiences.forEach((x) => {
          x.evaluations.forEach((y) => {
            total_weighing = total_weighing + y.weighing;
          });
        });
        total_weighing_sixty = total_weighing * 0.6;
      }
      return total_weighing_sixty;
    },
    getAllTotalWeighing() {
      let total_partial = this.getTotalWeighing;
      let total_transversal = 0;
      let total_evaluations = 0;

      if (this.suitcase_report) {
        this.suitcase_report.transversal_exams.forEach((x) => {
          total_transversal = total_transversal + x.weighing;
        });
        total_evaluations = total_partial + total_transversal;
      }
      return total_evaluations;
    },
    countRowspanEvaluation() {
      let number_evaluation = 0;
      if (this.suitcase_report) {
        this.suitcase_report.learning_experiences.forEach((x) => {
          if (x.evaluations.length == 0) {
            number_evaluation += 1;
          }
          number_evaluation = number_evaluation + x.evaluations.length;
        });
      }
      return number_evaluation;
    },
    egressProfile() {
      if (!this.egressProfileMatter) return null;
      return this.egressProfiles.find(
        (x) => x.id == this.egressProfileMatter.egress_profile
      );
    },
    // egressProfileLinked() {
    //   if (!this.egress_profile_linkage) return null;
    //   return this.egressProfiles.find(
    //     (x) => x.id == this.egress_profile_linkage.linked_egress_profile
    //   );
    // },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    // allows_crud() {
    //   if (
    //     this.career &&
    //     this.egressProfile &&
    //     !this.egressProfile.is_closed &&
    //     this.egressProfileMatter &&
    //     !this.egressProfileMatter.is_closed &&
    //     ((this.user_position &&
    //       [1, 2].includes(this.user_position.position) &&
    //       [1, 2].includes(this.user.groups[0])) ||
    //       this.user.is_superuser ||
    //       this.$hasObjectPermission(
    //         "mesh.faculty",
    //         "change",
    //         this.career.faculty
    //       ) ||
    //       this.$hasObjectPermission(
    //         "mesh.career",
    //         "change",
    //         this.egressProfile.career
    //       ))
    //   ) {
    //     const has_permission = this.$hasPermissions("teaching.change_matter");
    //     if (has_permission) {
    //       this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
    //       return has_permission;
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
    //     return false;
    //   }
    // },
  },
  methods: {
    // updateFerRecord(old_matter, text) {
    //   let payload = {
    //     matter_id: old_matter.id,
    //     item: {
    //       fer_record: text,
    //     },
    //   };
    //   this.$store.dispatch(names.PATCH_MATTER, payload).then(() => {
    //     toast(
    //       "Recursos por Ambiente de Aprendizaje (referencia Ficha FER) actualizado."
    //     );
    //   });
    // },

    distinctCareer(competence_dict_list) {
      return [
        ...new Set(competence_dict_list.map((competence) => competence.career)),
      ];
    },
    fetchSuitcaseReport() {
      if (this.egress_profile_matter_id) {
        this.$restful
          .Get(
            `/teaching/learning_suitcase/?egress_profile_matter=${this.egress_profile_matter_id}&learning_suitcase_option=4`
          )
          .then((response) => {
            this.suitcase_report = response;
          });
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(names.RESET_TITLE_OF_THE_PRINT);
  },
  mounted() {
    this.fetchSuitcaseReport();
    if (this.egress_profile_matter_id)
      this.$store
        .dispatch(
          names.FETCH_EGRESS_PROFILE_MATTER,
          this.egress_profile_matter_id
        )
        .then((egress_profile_matter) => {
          this.$store
            .dispatch(names.FETCH_MATTER, egress_profile_matter.matter)
            .then((matter) => {
              this.$store.commit(
                names.MUTATE_TITLE_OF_THE_PRINT,
                `PIA - ${matter.code} ${matter.name}`
              );
            });
          // this.$restful
          //   .Get(
          //     `/mesh/egress-profile-linkage/?linker_egress_profile=${egress_profile_matter.egress_profile}`
          //   )
          //   .then((response) => {
          //     if (response[0]) {
          //       this.egress_profile_linkage = response[0];
          //       this.$store.dispatch(
          //         names.FETCH_EGRESS_PROFILE,
          //         response[0].linked_egress_profile
          //       );
          //     }
          //   });
          this.$store
            .dispatch(
              names.FETCH_EGRESS_PROFILE,
              egress_profile_matter.egress_profile
            )
            .then((egress_profile) => {
              this.$store.dispatch(names.FETCH_CAREER, egress_profile.career);
            });
        });
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.ra-badge {
  border: 1px solid black;
  font-size: 9pt;
  letter-spacing: 0.3px;
}
.table-container2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 10px 0px 0px 0px;
  gap: 0px;
  width: 100%;
}
.title-primary-cell {
  align-items: center;
  color: black;
  font-weight: bold;
  text-align: left;
}
.table-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}
.primary-cell {
  height: 100%;
  display: flex;
  width: 40%;
  border-bottom: 1px solid #dee2e6;
  padding: 0.75rem;
  align-items: center;
  background-color: #d9d9d9 !important;
  color: black;
  font-weight: bold;
  text-align: start;
  box-sizing: border-box;
}
.secundary-cell {
  padding: 0.75rem;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  width: 60%;
  flex-wrap: wrap;
  text-align: start;
  align-items: center;
}
.item-one {
  font-weight: bold;
  padding: 0.7rem;
  text-align: left;
}
.transversal-app {
  background-color: #d9d9d9 !important;
  vertical-align: middle;
  min-width: 15em;
}
.suitcase-header {
  display: flex;
  position: relative;
  height: 56px;
  align-items: center;
}
.suitcase-faculty {
  width: 100%;
  margin-left: 1%;
  margin-right: 1em;
}
.suitcase-title {
  max-width: 100px;
  vertical-align: top;
  font-weight: bold;
  letter-spacing: 2.5px !important;
  line-height: 0.9;
}
.suitcase-title > .suitcase-title-2 {
  letter-spacing: -2px !important;
  font-weight: 100 !important;
}
.logo-container {
  display: flex;
  margin-left: auto;
  margin-right: 5em;
}
.logo-section {
  border-left: 1px solid;
  padding-left: 1em;
}
.logo-section > .institution-logo {
  max-height: 50px;
  transform: translateY(-25%);
  z-index: 1;
}
.left-header-text {
  width: 20%;
  margin-left: 1px;
}
.synthesis-header-container {
  display: flex;
  margin-left: 1px;
}
.empty-block {
  width: 5%;
}
.synthesis-header {
  background-color: black !important;
  width: 95%;
  margin-right: 1px;
  z-index: 10;
}
.first-header {
  background-color: #808080 !important;
  color: white;
}
.second-header {
  vertical-align: middle;
  background-color: #d9d9d9 !important;
}
.general-background-icon {
  padding: 0.3em;
  border-radius: 30px;
  border: 1px dotted;
}
.pre-requisites-tag {
  font-size: var(--secondary-font-size);
  margin-right: 2px;
  margin-bottom: 2px;
}
.suitcase-program-indications {
  border: 1px solid #dee2e6;
}
.program-indications-header {
  background: #808080 !important;
  color: white;
  border-bottom: 1px solid #dee2e6;
  font-weight: bold;
  padding: 0.7em;
}
.program-indications-body {
  padding: 1em;
}
.suitcase-matter-description {
  border: 1px solid #dee2e6;
}
.matter-description-header {
  padding: 0.7em;
  background-color: #808080 !important;
  color: white;
  font-weight: bold;
}
.matter-description-body {
  padding: 1em;
}
.suitcase-competences-th {
  vertical-align: middle;
  background-color: #808080 !important;
  color: white;
  min-width: 18em;
}
.suitcase-competences-td {
  text-align: left;
}
.suitcase-learning-results-th {
  vertical-align: middle;
  background-color: #808080 !important;
  color: white;
  min-width: 18em;
}
.suitcase-learning-results-td {
  text-align: left;
}
.learning-results-synthesis-th {
  background-color: #808080 !important;
  color: white;
}
.teaching-profile-header {
  vertical-align: middle;
  background-color: #d9d9d9 !important;
}
.third-header {
  background-color: #525252 !important;
  color: white;
}
.fourth-header {
  color: white;
  background-color: #3c3c3c !important;
}
.fifth-header {
  background-color: #bfbfbf !important;
}
.assistant-header {
  padding: 0.7em;
  background-color: #808080 !important;
  color: white;
  font-weight: bold;
}
.assistant-container {
  display: flex;
  border: 1px solid #dee2e6;
}
.assistant-drop-header {
  background-color: #d9d9d9 !important;
  font-weight: bold;
}
.assistant-content {
  padding: 1em;
  border-right: 1px solid #dee2e6 !important;
}
.biblio-header {
  vertical-align: middle;
  background-color: #d9d9d9 !important;
}
.learning-result {
  display: none;
}
@media print {
  .learning-result {
    display: flex;
    text-align: justify;
  }
  .badge {
    border: none;
  }
  .bibliography-content {
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 600px !important;
  }
}
.page-break {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
.descriptor-body {
  display: block;
  text-align: justify;
  padding: 0.4em;
  padding-inline: 1em;
}
.descriptor-body >>> p {
  margin-bottom: 0 !important;
}
</style>